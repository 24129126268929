import React, { useState, memo } from 'react';
import { getOptimizedImageUrl, generateImageSrcSet } from '../utils/imageUtils';

interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src: string;
  alt: string;
  title?: string;
  description?: string;
  className?: string;
  width?: number;
  height?: number;
  loading?: 'lazy' | 'eager';
  priority?: boolean;
  quality?: number;
}

export const Image = memo(function Image({
  src,
  alt,
  title,
  description,
  className = '',
  width,
  height,
  loading = 'lazy',
  priority = false,
  quality = 80,
  ...props
}: ImageProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const isLogo = src.includes('logo.png');
  const optimizedSrc = isLogo ? src : width ? getOptimizedImageUrl(src, width, quality) : src;
  const srcSet = isLogo ? undefined : generateImageSrcSet(src);

  return (
    <div 
      className={`relative ${className}`}
      style={{ aspectRatio: width && height ? `${width}/${height}` : undefined }}
    >
      {isLoading && !isLogo && (
        <div 
          className="absolute inset-0 bg-gray-100 animate-pulse"
          style={{ aspectRatio: width && height ? `${width}/${height}` : undefined }}
        />
      )}
      <picture>
        {isLogo && <source srcSet="/logo.webp" type="image/webp" />}
        <img
          src={hasError ? '/images/placeholder.jpg' : optimizedSrc}
          alt={alt}
          title={title || alt}
          loading={priority ? 'eager' : loading}
          decoding="async"
          onLoad={() => setIsLoading(false)}
          onError={() => {
            setIsLoading(false);
            setHasError(true);
          }}
          className={`${className} ${isLoading ? 'opacity-0' : 'opacity-100'} transition-opacity duration-300`}
          srcSet={srcSet}
          sizes={isLogo ? undefined : "(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"}
          width={width}
          height={height}
          aria-label={description}
          {...props}
        />
      </picture>
    </div>
  );
});