import React, { useState } from 'react';
import { Link2, BarChart, Globe } from 'lucide-react';

export function BacklinkAnalyzer() {
  const [domain, setDomain] = useState('');
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<any>(null);

  const analyzeBacklinks = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    // Simulate API call
    setTimeout(() => {
      setResults({
        totalBacklinks: 156,
        domainAuthority: 35,
        topBacklinks: [
          {
            domain: 'example.com',
            authority: 45,
            type: 'dofollow',
            anchor: 'sacramento seo company'
          }
        ],
        categories: {
          dofollow: 120,
          nofollow: 36,
          educational: 25,
          government: 5
        }
      });
      setLoading(false);
    }, 1500);
  };

  return (
    <div className="max-w-4xl mx-auto">
      <h2 className="text-2xl font-bold mb-6">Backlink Analysis Tool</h2>
      
      <form onSubmit={analyzeBacklinks} className="space-y-6 mb-8">
        <div>
          <label htmlFor="domain" className="block text-sm font-medium text-gray-700 mb-1">
            Website URL
          </label>
          <input
            type="url"
            id="domain"
            value={domain}
            onChange={(e) => setDomain(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>

        <button
          type="submit"
          disabled={loading}
          className="w-full bg-blue-600 text-white py-3 px-6 rounded-md hover:bg-blue-700 transition-colors disabled:opacity-50"
        >
          {loading ? 'Analyzing...' : 'Analyze Backlinks'}
        </button>
      </form>

      {results && (
        <div className="space-y-8">
          {/* Overview Stats */}
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            <div className="bg-gray-50 p-4 rounded-lg">
              <div className="flex items-center text-sm text-gray-600 mb-1">
                <Link2 className="w-4 h-4 mr-1" />
                Total Backlinks
              </div>
              <div className="text-2xl font-bold">{results.totalBacklinks}</div>
            </div>
            <div className="bg-gray-50 p-4 rounded-lg">
              <div className="flex items-center text-sm text-gray-600 mb-1">
                <BarChart className="w-4 h-4 mr-1" />
                Domain Authority
              </div>
              <div className="text-2xl font-bold">{results.domainAuthority}</div>
            </div>
            {/* Add more stats */}
          </div>

          {/* Backlink Categories */}
          <div>
            <h3 className="font-semibold mb-4">Backlink Categories</h3>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
              {Object.entries(results.categories).map(([category, count]: [string, any]) => (
                <div key={category} className="bg-white border rounded-lg p-4">
                  <div className="text-sm text-gray-600 mb-1 capitalize">{category}</div>
                  <div className="text-xl font-bold">{count}</div>
                </div>
              ))}
            </div>
          </div>

          {/* Top Backlinks */}
          <div>
            <h3 className="font-semibold mb-4">Top Backlinks</h3>
            <div className="space-y-4">
              {results.topBacklinks.map((link: any, index: number) => (
                <div key={index} className="bg-white border rounded-lg p-4">
                  <div className="flex justify-between items-center">
                    <div>
                      <div className="font-medium">{link.domain}</div>
                      <div className="text-sm text-gray-600">
                        Anchor: {link.anchor}
                      </div>
                    </div>
                    <div className="flex items-center space-x-4">
                      <span className="text-sm text-gray-600">DA: {link.authority}</span>
                      <span className={`px-2 py-1 rounded text-sm ${
                        link.type === 'dofollow' ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-800'
                      }`}>
                        {link.type}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}