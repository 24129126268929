import React from 'react';
import { Link } from 'react-router-dom';
import { Search, Target, BarChart, Users, Globe, Smartphone } from 'lucide-react';
import { CTAButton } from '../components/CTAButton';

export function ServicesPage() {
  return (
    <>
      {/* Hero Section */}
      <section className="bg-gradient-to-r from-blue-600 to-blue-800 text-white py-20">
        <div className="container mx-auto px-4">
          <h1 className="text-5xl font-bold mb-6">
            Folsom Digital Marketing Services
          </h1>
          <p className="text-xl max-w-2xl mb-8">
            Comprehensive digital marketing solutions tailored to help your business grow. 
            From local SEO to social media management, we've got you covered.
          </p>
          <CTAButton
            text="Schedule Your Free Strategy Call"
            href="/contact"
            className="text-lg"
          />
        </div>
      </section>

      {/* Services Grid */}
      <section className="py-16">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {services.map((service) => (
              <ServiceCard key={service.title} {...service} />
            ))}
          </div>
        </div>
      </section>

      {/* Why Choose Us */}
      <section className="bg-gray-50 py-16">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">
            Why Choose Gold Standard Local SEO?
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {benefits.map((benefit, index) => (
              <div key={index} className="bg-white p-6 rounded-lg shadow">
                <h3 className="text-xl font-semibold mb-4">{benefit.title}</h3>
                <p className="text-gray-600">{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="bg-blue-900 text-white py-16">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-6">
            Ready to Dominate Your Local Market?
          </h2>
          <p className="text-xl mb-8 max-w-2xl mx-auto">
            Let's create a customized digital marketing strategy that drives real results for your business.
          </p>
          <CTAButton
            text="Get Your Free Strategy Session"
            href="/contact"
            className="text-lg"
          />
        </div>
      </section>
    </>
  );
}

function ServiceCard({ title, description, icon: Icon, href }) {
  return (
    <Link to={href} className="block">
      <div className="bg-white p-8 rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 h-full">
        <Icon className="w-12 h-12 text-blue-600 mb-4" />
        <h3 className="text-xl font-semibold mb-4">{title}</h3>
        <p className="text-gray-600">{description}</p>
      </div>
    </Link>
  );
}

const services = [
  {
    title: "Local SEO",
    description: "Dominate local search results and attract more customers in Folsom and surrounding areas.",
    icon: Search,
    href: "/services/local-seo"
  },
  {
    title: "PPC Management",
    description: "Drive instant traffic and leads with expertly managed Google Ads campaigns.",
    icon: Target,
    href: "/services/ppc"
  },
  {
    title: "Social Media Marketing",
    description: "Build brand awareness and engage with your target audience on social platforms.",
    icon: Users,
    href: "/services/social-media"
  },
  {
    title: "Web Design",
    description: "Create stunning, conversion-focused websites that drive results.",
    icon: Globe,
    href: "/services/web-design"
  },
  {
    title: "Analytics & Reporting",
    description: "Get detailed insights into your digital marketing performance.",
    icon: BarChart,
    href: "/services/analytics"
  },
  {
    title: "Mobile Marketing",
    description: "Reach customers on their mobile devices with targeted campaigns.",
    icon: Smartphone,
    href: "/services/mobile-marketing"
  }
];

const benefits = [
  {
    title: "Data-Driven Approach",
    description: "We make decisions based on real data and analytics, ensuring your marketing budget is spent effectively."
  },
  {
    title: "Local Expertise",
    description: "Deep understanding of the Folsom market and what works for local businesses."
  },
  {
    title: "Proven Results",
    description: "Track record of helping businesses achieve significant ROI through digital marketing."
  },
  {
    title: "Transparent Reporting",
    description: "Regular, detailed reports showing exactly how your campaigns are performing."
  },
  {
    title: "Dedicated Support",
    description: "Your success is our priority. Get personalized attention and support when you need it."
  },
  {
    title: "Cutting-Edge Strategies",
    description: "Stay ahead of the competition with the latest digital marketing techniques."
  }
];