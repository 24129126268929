import React from 'react';

interface CTAButtonProps {
  text: string;
  href: string;
  variant?: 'primary' | 'secondary';
  className?: string;
}

export function CTAButton({ text, href, variant = 'primary', className = '' }: CTAButtonProps) {
  const baseStyles = "px-6 py-3 rounded-full font-semibold transition-all duration-300 inline-flex items-center";
  const variantStyles = {
    primary: "bg-primary text-secondary-900 hover:bg-primary-300 hover:shadow-lg",
    secondary: "bg-secondary-900 text-primary border-2 border-primary hover:bg-secondary-800"
  };

  return (
    <a
      href={href}
      className={`${baseStyles} ${variantStyles[variant]} ${className}`}
    >
      {text}
    </a>
  );
}