import React, { useEffect } from 'react';
import { MapContainer, TileLayer, Circle, Marker, Popup } from 'react-leaflet';
import { Phone, Mail, MapPin, Clock } from 'lucide-react';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Fix for default marker icons in Leaflet
delete (L.Icon.Default.prototype as any)._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

export function LocalPresence() {
  const folsomCoordinates: [number, number] = [38.672767, -121.165277];
  const serviceAreas = [
    { name: 'Folsom', coordinates: [38.6747, -121.1760] },
    { name: 'El Dorado Hills', coordinates: [38.6857, -121.0821] },
    { name: 'Granite Bay', coordinates: [38.7634, -121.1637] },
    { name: 'Roseville', coordinates: [38.7521, -121.2880] },
    { name: 'Sacramento', coordinates: [38.5816, -121.4944] }
  ];

  return (
    <section className="py-16 bg-gray-50">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12">Our Service Areas</h2>
        
        {/* Map Container with Schema.org data */}
        <div 
          className="w-full h-[600px] rounded-lg shadow-lg mb-12"
          itemScope 
          itemType="https://schema.org/Map"
        >
          <meta itemProp="name" content="Gold Standard Local SEO Service Areas Map" />
          <MapContainer
            center={folsomCoordinates}
            zoom={11}
            style={{ height: '100%', width: '100%' }}
            className="rounded-lg"
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            
            {/* Service Area Circle */}
            <Circle
              center={folsomCoordinates}
              radius={40000}
              pathOptions={{
                color: '#D4AF37',
                fillColor: '#D4AF37',
                fillOpacity: 0.15
              }}
            />

            {/* Main Office Marker */}
            <Marker position={folsomCoordinates}>
              <Popup>
                <div itemScope itemType="https://schema.org/LocalBusiness">
                  <div className="font-semibold" itemProp="name">Gold Standard Local SEO</div>
                  <div itemScope itemType="https://schema.org/PostalAddress">
                    <div className="text-sm" itemProp="streetAddress">705 Gold Lake Drive, Suite 250</div>
                    <div className="text-sm">
                      <span itemProp="addressLocality">Folsom</span>,{' '}
                      <span itemProp="addressRegion">CA</span>{' '}
                      <span itemProp="postalCode">95630</span>
                    </div>
                  </div>
                </div>
              </Popup>
            </Marker>

            {/* Service Area Markers */}
            {serviceAreas.map((area) => (
              <Marker
                key={area.name}
                position={area.coordinates as [number, number]}
              >
                <Popup>
                  <div itemScope itemType="https://schema.org/City">
                    <span itemProp="name">{area.name}</span>
                  </div>
                </Popup>
              </Marker>
            ))}
          </MapContainer>
        </div>

        {/* Service Areas Grid with Schema.org data */}
        <div 
          className="grid grid-cols-1 md:grid-cols-3 gap-8"
          itemScope 
          itemType="https://schema.org/Organization"
        >
          <meta itemProp="name" content="Gold Standard Local SEO" />
          
          {/* Sacramento County */}
          <div 
            className="bg-white p-6 rounded-lg shadow-md"
            itemScope 
            itemType="https://schema.org/AdministrativeArea"
          >
            <h3 className="text-xl font-semibold mb-4 flex items-center">
              <MapPin className="w-5 h-5 mr-2 text-primary" />
              <span itemProp="name">Sacramento County</span>
            </h3>
            <ul className="space-y-4" itemProp="containsPlace">
              <li itemScope itemType="https://schema.org/City">
                <span className="font-medium block" itemProp="name">Sacramento</span>
                <p className="text-sm text-gray-600" itemProp="description">Downtown & Midtown</p>
              </li>
              <li itemScope itemType="https://schema.org/City">
                <span className="font-medium block" itemProp="name">Folsom</span>
                <p className="text-sm text-gray-600" itemProp="description">Historic District & Intel Campus</p>
              </li>
              <li itemScope itemType="https://schema.org/City">
                <span className="font-medium block" itemProp="name">Rancho Cordova</span>
                <p className="text-sm text-gray-600" itemProp="description">Business district</p>
              </li>
            </ul>
          </div>
          
          {/* Placer County */}
          <div 
            className="bg-white p-6 rounded-lg shadow-md"
            itemScope 
            itemType="https://schema.org/AdministrativeArea"
          >
            <h3 className="text-xl font-semibold mb-4 flex items-center">
              <MapPin className="w-5 h-5 mr-2 text-primary" />
              <span itemProp="name">Placer County</span>
            </h3>
            <ul className="space-y-4" itemProp="containsPlace">
              <li itemScope itemType="https://schema.org/City">
                <span className="font-medium block" itemProp="name">Roseville</span>
                <p className="text-sm text-gray-600" itemProp="description">Retail & business hub</p>
              </li>
              <li itemScope itemType="https://schema.org/City">
                <span className="font-medium block" itemProp="name">Rocklin</span>
                <p className="text-sm text-gray-600" itemProp="description">Growing community</p>
              </li>
              <li itemScope itemType="https://schema.org/City">
                <span className="font-medium block" itemProp="name">Granite Bay</span>
                <p className="text-sm text-gray-600" itemProp="description">Luxury market</p>
              </li>
            </ul>
          </div>
          
          {/* El Dorado County */}
          <div 
            className="bg-white p-6 rounded-lg shadow-md"
            itemScope 
            itemType="https://schema.org/AdministrativeArea"
          >
            <h3 className="text-xl font-semibold mb-4 flex items-center">
              <MapPin className="w-5 h-5 mr-2 text-primary" />
              <span itemProp="name">El Dorado County</span>
            </h3>
            <ul className="space-y-4" itemProp="containsPlace">
              <li itemScope itemType="https://schema.org/City">
                <span className="font-medium block" itemProp="name">El Dorado Hills</span>
                <p className="text-sm text-gray-600" itemProp="description">Town Center area</p>
              </li>
              <li itemScope itemType="https://schema.org/City">
                <span className="font-medium block" itemProp="name">Cameron Park</span>
                <p className="text-sm text-gray-600" itemProp="description">Local businesses</p>
              </li>
              <li itemScope itemType="https://schema.org/City">
                <span className="font-medium block" itemProp="name">Shingle Springs</span>
                <p className="text-sm text-gray-600" itemProp="description">Growing community</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}