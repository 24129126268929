import { BlogPost } from '../types';
import { seoToolsArticles } from './blogArticles/seoTools';
import { citySpecificSEOArticles } from './blogArticles/citySpecificSEO';
import { industrySpecificSEOArticles } from './blogArticles/industrySpecificSEO';
import { sacramentoAreaArticles } from './blogArticles/sacramentoArea';
import { rosevilleAreaArticles } from './blogArticles/rosevilleArea';
import { elDoradoAreaArticles } from './blogArticles/elDoradoArea';
import { placerAreaArticles } from './blogArticles/placer-area';
import { sacramentoSuburbArticles } from './blogArticles/sacramento-suburbs';
import { tahoeAreaArticles } from './blogArticles/tahoe-area';

// Combine all blog posts
export const allBlogPosts: BlogPost[] = [
  ...seoToolsArticles,
  ...citySpecificSEOArticles,
  ...industrySpecificSEOArticles,
  ...sacramentoAreaArticles,
  ...rosevilleAreaArticles,
  ...elDoradoAreaArticles,
  ...placerAreaArticles,
  ...sacramentoSuburbArticles,
  ...tahoeAreaArticles
];