import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';
import { BlogPost } from '../../types';
import { allBlogPosts } from '../../data/blogPosts';
import { Image } from '../Image';

interface RelatedPostsProps {
  currentPost: BlogPost;
}

export function RelatedPosts({ currentPost }: RelatedPostsProps) {
  const relatedPosts = currentPost.relatedPosts
    .map(slug => allBlogPosts.find(post => post.slug === slug))
    .filter((post): post is BlogPost => post !== undefined);

  return (
    <div className="mt-12">
      <h2 className="text-2xl font-bold mb-6">Related Articles</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {relatedPosts.map((post) => (
          <article key={post.id} className="bg-white rounded-lg shadow-md overflow-hidden">
            <Image
              src={post.image}
              alt={`Featured image for ${post.title}`}
              title={post.title}
              description={post.excerpt}
              className="w-full h-40 object-cover"
              width={400}
              height={225}
            />
            <div className="p-4">
              <h3 className="font-semibold mb-2">
                <Link to={`/blog/${post.slug}`} className="hover:text-blue-600">
                  {post.title}
                </Link>
              </h3>
              <Link
                to={`/blog/${post.slug}`}
                className="inline-flex items-center text-sm text-blue-600 hover:text-blue-700"
              >
                Read More <ArrowRight className="w-4 h-4 ml-1" />
              </Link>
            </div>
          </article>
        ))}
      </div>
    </div>
  );
}