export function getOptimizedImageUrl(url: string, width: number, quality: number = 80): string {
  if (url.includes('images.unsplash.com')) {
    // Add art direction and focal point
    return `${url}?auto=format,compress&w=${width}&q=${quality}&fit=crop&crop=faces,center`;
  }
  return url;
}

export function generateImageSrcSet(url: string): string {
  if (!url.includes('images.unsplash.com')) return '';
  
  const sizes = [320, 640, 768, 1024, 1280, 1536, 1920];
  return sizes
    .map(size => `${getOptimizedImageUrl(url, size)} ${size}w`)
    .join(', ');
}

export function getImageDimensions(type: 'hero' | 'card' | 'thumbnail' | 'related'): { width: number; height: number } {
  switch (type) {
    case 'hero':
      return { width: 1920, height: 1080 }; // 16:9 aspect ratio
    case 'card':
      return { width: 800, height: 450 }; // 16:9 aspect ratio
    case 'thumbnail':
      return { width: 400, height: 225 }; // 16:9 aspect ratio
    case 'related':
      return { width: 600, height: 338 }; // 16:9 aspect ratio
    default:
      return { width: 800, height: 450 };
  }
}