import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Search } from 'lucide-react';
import { SearchBar } from '../components/SearchBar';
import { useNavigate } from 'react-router-dom';
import { allBlogPosts } from '../data/blogPosts';

export function SearchPage() {
  const [searchParams] = useSearchParams();
  const query = searchParams.get('q') || '';
  const [results, setResults] = useState<any[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (query) {
      const searchResults = allBlogPosts.filter(post => 
        post.title.toLowerCase().includes(query.toLowerCase()) ||
        post.content.toLowerCase().includes(query.toLowerCase()) ||
        post.keywords.some(keyword => 
          keyword.toLowerCase().includes(query.toLowerCase())
        )
      );
      setResults(searchResults);
    }
  }, [query]);

  const handleSearch = (newQuery: string) => {
    navigate(`/search?q=${encodeURIComponent(newQuery)}`);
  };

  return (
    <div className="py-16">
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-3xl font-bold mb-8">Search Results</h1>
          
          <div className="mb-8">
            <SearchBar onSearch={handleSearch} />
          </div>

          {query ? (
            <>
              <p className="text-gray-600 mb-8">
                Found {results.length} results for "{query}"
              </p>

              {results.length > 0 ? (
                <div className="space-y-6">
                  {results.map((result, index) => (
                    <article key={index} className="bg-white p-6 rounded-lg shadow-md">
                      <h2 className="text-xl font-semibold mb-2">
                        <a 
                          href={`/blog/${result.slug}`}
                          className="hover:text-blue-600 transition-colors"
                        >
                          {result.title}
                        </a>
                      </h2>
                      <p className="text-gray-600 mb-4">{result.excerpt}</p>
                      <div className="flex items-center text-sm text-gray-500">
                        <Search className="w-4 h-4 mr-2" />
                        <span>Matches found in: title, content, keywords</span>
                      </div>
                    </article>
                  ))}
                </div>
              ) : (
                <div className="text-center py-12">
                  <p className="text-gray-600">No results found. Try different keywords.</p>
                </div>
              )}
            </>
          ) : (
            <div className="text-center py-12">
              <p className="text-gray-600">Enter a search term to find content.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}