import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const categories = [
  { name: 'All', slug: '' },
  { name: 'Local SEO', slug: 'local-seo' },
  { name: 'PPC', slug: 'ppc' },
  { name: 'Social Media', slug: 'social-media' },
  { name: 'Web Design', slug: 'web-design' }
];

export function BlogCategories() {
  const location = useLocation();
  const currentCategory = location.pathname.split('/').pop() || '';

  return (
    <div className="mb-12">
      <div className="flex flex-wrap justify-center gap-4">
        {categories.map((category) => (
          <Link
            key={category.slug}
            to={category.slug ? `/blog/category/${category.slug}` : '/blog'}
            className={`px-6 py-2 rounded-full transition-colors ${
              currentCategory === category.slug
                ? 'bg-primary text-white'
                : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
            }`}
          >
            {category.name}
          </Link>
        ))}
      </div>
    </div>
  );
}