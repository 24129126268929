import { analyzeDomain } from './analysis/domainAnalyzer';
import { analyzeKeywords } from './analysis/keywordAnalyzer';
import { analyzeBacklinks } from './analysis/backlinkAnalyzer';
import { analyzeContentGaps } from './analysis/contentGapAnalyzer';

export interface CompetitorData {
  overview: {
    domainAuthority: number;
    totalBacklinks: number;
    organicKeywords: number;
    monthlyTraffic: number;
  };
  topKeywords: Array<{
    term: string;
    position: number;
    volume: number;
    difficulty: number;
  }>;
  contentGaps: Array<{
    topic: string;
    opportunity: 'high' | 'medium' | 'low';
    suggestedKeywords: string[];
  }>;
  backlinks: Array<{
    domain: string;
    authority: number;
    type: 'dofollow' | 'nofollow';
  }>;
}

export async function analyzeCompetitor(url: string, location: string): Promise<CompetitorData> {
  try {
    // Extract domain from URL
    const domain = new URL(url).hostname;
    
    // Run all analyses in parallel
    const [
      metrics,
      keywords,
      backlinks,
      gaps
    ] = await Promise.all([
      analyzeDomain(domain),
      analyzeKeywords(domain, location),
      analyzeBacklinks(domain),
      analyzeContentGaps(domain, location)
    ]);

    return {
      overview: {
        domainAuthority: metrics.domainAuthority,
        totalBacklinks: metrics.totalBacklinks,
        organicKeywords: keywords.total,
        monthlyTraffic: metrics.monthlyTraffic
      },
      topKeywords: keywords.top,
      contentGaps: gaps,
      backlinks
    };
  } catch (error) {
    console.error('Competitor analysis error:', error);
    throw new Error('Failed to analyze competitor website');
  }
}