import React from 'react';
import { Image } from '../Image';

interface BlogImageProps {
  src: string;
  alt: string;
  title: string;
  description: string;
  className?: string;
  width?: number;
  height?: number;
  priority?: boolean;
}

export function BlogImage({ 
  src, 
  alt, 
  title, 
  description, 
  className = '', 
  width = 1200, 
  height = 630,
  priority = false 
}: BlogImageProps) {
  return (
    <Image
      src={src}
      alt={alt}
      title={title}
      description={description}
      className={`rounded-lg shadow-lg overflow-hidden ${className}`}
      width={width}
      height={height}
      loading={priority ? 'eager' : 'lazy'}
    />
  );
}