export function reportWebVitals() {
  if (typeof window !== 'undefined' && 'performance' in window) {
    // Core Web Vitals
    const vitalsReport = {
      fcp: null as number | null,
      lcp: null as number | null,
      cls: null as number | null,
      fid: null as number | null,
      ttfb: null as number | null
    };

    // First Contentful Paint
    new PerformanceObserver((entryList) => {
      const entries = entryList.getEntries();
      if (entries.length > 0) {
        vitalsReport.fcp = entries[0].startTime;
        sendVitalsReport();
      }
    }).observe({ entryTypes: ['paint'] });

    // Largest Contentful Paint
    new PerformanceObserver((entryList) => {
      const entries = entryList.getEntries();
      if (entries.length > 0) {
        vitalsReport.lcp = entries[entries.length - 1].startTime;
        sendVitalsReport();
      }
    }).observe({ entryTypes: ['largest-contentful-paint'] });

    // Cumulative Layout Shift
    new PerformanceObserver((entryList) => {
      let clsValue = 0;
      for (const entry of entryList.getEntries()) {
        if (!(entry as any).hadRecentInput) {
          clsValue += (entry as any).value;
        }
      }
      vitalsReport.cls = clsValue;
      sendVitalsReport();
    }).observe({ entryTypes: ['layout-shift'] });

    // First Input Delay
    new PerformanceObserver((entryList) => {
      const entries = entryList.getEntries();
      if (entries.length > 0) {
        vitalsReport.fid = entries[0].duration;
        sendVitalsReport();
      }
    }).observe({ entryTypes: ['first-input'] });

    // Time to First Byte
    const navigation = performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming;
    if (navigation) {
      vitalsReport.ttfb = navigation.responseStart - navigation.requestStart;
      sendVitalsReport();
    }

    function sendVitalsReport() {
      // Only send report when all metrics are collected
      if (Object.values(vitalsReport).every(value => value !== null)) {
        fetch('/api/vitals', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            ...vitalsReport,
            url: window.location.href,
            timestamp: new Date().toISOString()
          }),
          keepalive: true
        }).catch(console.error);
      }
    }
  }
}