import React from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { Calendar, User, Tag } from 'lucide-react';
import { SEOHead } from '../../components/SEOHead';
import { TopicalNav } from '../../components/blog/TopicalNav';
import { RelatedPosts } from '../../components/blog/RelatedPosts';
import { BlogImage } from '../../components/blog/BlogImage';
import { allBlogPosts } from '../../data/blogPosts';
import { formatDate } from '../../utils/dateUtils';

export function BlogPostPage() {
  const { slug } = useParams();
  const post = allBlogPosts.find(post => post.slug === slug);

  if (!post) {
    return <Navigate to="/blog" replace />;
  }

  return (
    <>
      <SEOHead
        title={post.title}
        description={post.excerpt}
        canonicalUrl={post.canonicalUrl || `/blog/${post.slug}`}
        schema={post.schema}
        keywords={post.keywords}
        imageUrl={post.image}
      />

      <article className="py-16">
        <div className="container mx-auto px-4 max-w-4xl">
          <BlogImage
            src={post.image}
            alt={`Featured image for ${post.title}`}
            title={post.title}
            description={post.excerpt}
            width={1200}
            height={630}
            priority={true}
            className="w-full h-[400px] object-cover rounded-lg shadow-lg mb-8"
          />

          {/* Article Header */}
          <header className="mb-8">
            <h1 className="text-4xl font-bold mb-4">{post.title}</h1>
            <div className="flex items-center text-gray-600 space-x-6">
              <div className="flex items-center">
                <Calendar className="w-5 h-5 mr-2" />
                <time dateTime={post.date}>{formatDate(post.date)}</time>
              </div>
              <div className="flex items-center">
                <User className="w-5 h-5 mr-2" />
                <span>{post.author}</span>
              </div>
              <div className="flex items-center">
                <Tag className="w-5 h-5 mr-2" />
                <span>{post.category}</span>
              </div>
            </div>
          </header>

          {/* Topical Navigation */}
          <TopicalNav currentSlug={post.slug} cluster={post.topicalCluster} />

          {/* Article Content */}
          <div 
            className="prose prose-lg max-w-none mb-12"
            dangerouslySetInnerHTML={{ __html: post.content }}
          />

          {/* Related Posts */}
          <RelatedPosts currentPost={post} />
        </div>
      </article>
    </>
  );
}