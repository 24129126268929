export const socialMediaLinks = {
  facebook: "https://www.facebook.com/goldstandardassets",
  twitter: "https://x.com/GoldStandard24k",
  linkedin: "https://www.linkedin.com/in/goldstandard/",
  instagram: "https://www.instagram.com/gold_standard24k/",
  youtube: "https://www.youtube.com/@goldstandard24k",
  pinterest: "https://www.pinterest.com/goldstandard24k/"
};

export const socialMediaHandles = {
  facebook: "@goldstandardassets",
  twitter: "@GoldStandard24k",
  linkedin: "goldstandard",
  instagram: "@gold_standard24k",
  youtube: "@goldstandard24k",
  pinterest: "@goldstandard24k"
};

export const socialMediaProfiles = [
  {
    platform: "Facebook",
    url: socialMediaLinks.facebook,
    handle: socialMediaHandles.facebook,
    type: "Business Page",
    schema: {
      "@type": "Organization",
      "sameAs": socialMediaLinks.facebook
    }
  },
  {
    platform: "X (Twitter)",
    url: socialMediaLinks.twitter,
    handle: socialMediaHandles.twitter,
    type: "Business Profile",
    schema: {
      "@type": "Organization",
      "sameAs": socialMediaLinks.twitter
    }
  },
  {
    platform: "LinkedIn",
    url: socialMediaLinks.linkedin,
    handle: socialMediaHandles.linkedin,
    type: "Company Page",
    schema: {
      "@type": "Organization",
      "sameAs": socialMediaLinks.linkedin
    }
  },
  {
    platform: "Instagram",
    url: socialMediaLinks.instagram,
    handle: socialMediaHandles.instagram,
    type: "Business Account",
    schema: {
      "@type": "Organization",
      "sameAs": socialMediaLinks.instagram
    }
  },
  {
    platform: "YouTube",
    url: socialMediaLinks.youtube,
    handle: socialMediaHandles.youtube,
    type: "Brand Channel",
    schema: {
      "@type": "Organization",
      "sameAs": socialMediaLinks.youtube
    }
  },
  {
    platform: "Pinterest",
    url: socialMediaLinks.pinterest,
    handle: socialMediaHandles.pinterest,
    type: "Business Account",
    schema: {
      "@type": "Organization",
      "sameAs": socialMediaLinks.pinterest
    }
  }
];

export const socialMediaSchema = {
  "@context": "https://schema.org",
  "@type": "Organization",
  "name": "Gold Standard Local SEO",
  "url": "https://goldstandardlocalseo.com",
  "logo": "https://goldstandardlocalseo.com/logo.png",
  "sameAs": Object.values(socialMediaLinks)
};