import React, { useState } from 'react';
import { MapPin, Search, ExternalLink } from 'lucide-react';

export function GBPRankingChecker() {
  const [businessName, setBusinessName] = useState('');
  const [location, setLocation] = useState('');
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<any>(null);

  const checkRanking = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    // Simulate API call
    setTimeout(() => {
      setResults({
        rank: 3,
        totalListings: 25,
        categories: ['Digital Marketing Agency', 'SEO Service'],
        visibility: 85,
        recommendations: [
          'Add more business photos',
          'Respond to recent reviews',
          'Update business hours',
          'Add more services'
        ]
      });
      setLoading(false);
    }, 1500);
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-6">Google Business Profile Ranking Checker</h2>
      
      <form onSubmit={checkRanking} className="space-y-6 mb-8">
        <div>
          <label htmlFor="businessName" className="block text-sm font-medium text-gray-700 mb-1">
            Business Name
          </label>
          <input
            type="text"
            id="businessName"
            value={businessName}
            onChange={(e) => setBusinessName(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>
        
        <div>
          <label htmlFor="location" className="block text-sm font-medium text-gray-700 mb-1">
            Location
          </label>
          <input
            type="text"
            id="location"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>

        <button
          type="submit"
          disabled={loading}
          className="w-full bg-blue-600 text-white py-3 px-6 rounded-md hover:bg-blue-700 transition-colors disabled:opacity-50"
        >
          {loading ? 'Checking...' : 'Check GBP Ranking'}
        </button>
      </form>

      {results && (
        <div className="space-y-6">
          <div className="grid grid-cols-2 gap-4">
            <div className="bg-gray-50 p-4 rounded-lg">
              <div className="text-3xl font-bold text-blue-600 mb-1">{results.rank}</div>
              <div className="text-sm text-gray-600">Local Pack Ranking</div>
            </div>
            <div className="bg-gray-50 p-4 rounded-lg">
              <div className="text-3xl font-bold text-blue-600 mb-1">{results.visibility}%</div>
              <div className="text-sm text-gray-600">Local Visibility</div>
            </div>
          </div>

          <div>
            <h3 className="font-semibold mb-2">Business Categories</h3>
            <div className="flex flex-wrap gap-2">
              {results.categories.map((category: string, index: number) => (
                <span key={index} className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm">
                  {category}
                </span>
              ))}
            </div>
          </div>

          <div>
            <h3 className="font-semibold mb-2">Recommendations</h3>
            <ul className="space-y-2">
              {results.recommendations.map((rec: string, index: number) => (
                <li key={index} className="flex items-start">
                  <MapPin className="w-5 h-5 text-blue-600 mr-2 flex-shrink-0 mt-0.5" />
                  <span>{rec}</span>
                </li>
              ))}
            </ul>
          </div>

          <div className="pt-4 border-t">
            <a
              href="/contact"
              className="inline-flex items-center text-blue-600 hover:text-blue-700"
            >
              Get Expert Help <ExternalLink className="w-4 h-4 ml-2" />
            </a>
          </div>
        </div>
      )}
    </div>
  );
}