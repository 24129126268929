import React, { useState } from 'react';
import { Search, TrendingUp, BarChart, Globe, Award } from 'lucide-react';

export function KeywordTool() {
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<any>(null);

  // Top 10 high-value keywords for digital marketing agencies
  const primaryKeywords = [
    {
      term: "digital marketing agency",
      volume: 40500,
      difficulty: 67,
      intent: "commercial",
      cpc: 15.20
    },
    {
      term: "seo company",
      volume: 27100,
      difficulty: 71,
      intent: "commercial",
      cpc: 18.50
    },
    {
      term: "ppc management services",
      volume: 9900,
      difficulty: 63,
      intent: "commercial",
      cpc: 22.30
    },
    {
      term: "social media marketing agency",
      volume: 18100,
      difficulty: 65,
      intent: "commercial",
      cpc: 16.80
    },
    {
      term: "local seo services",
      volume: 8100,
      difficulty: 58,
      intent: "commercial",
      cpc: 19.40
    },
    {
      term: "best digital marketing agency",
      volume: 12400,
      difficulty: 69,
      intent: "commercial",
      cpc: 17.90
    },
    {
      term: "web design company",
      volume: 22800,
      difficulty: 64,
      intent: "commercial",
      cpc: 15.60
    },
    {
      term: "content marketing agency",
      volume: 5400,
      difficulty: 61,
      intent: "commercial",
      cpc: 14.30
    },
    {
      term: "ecommerce marketing agency",
      volume: 4900,
      difficulty: 59,
      intent: "commercial",
      cpc: 20.10
    },
    {
      term: "b2b digital marketing agency",
      volume: 3600,
      difficulty: 57,
      intent: "commercial",
      cpc: 23.40
    }
  ];

  const analyzeKeywords = async () => {
    setLoading(true);

    // Simulate API call with our predefined high-value keywords
    setTimeout(() => {
      setResults({
        overview: {
          totalVolume: primaryKeywords.reduce((acc, kw) => acc + kw.volume, 0),
          avgDifficulty: Math.round(primaryKeywords.reduce((acc, kw) => acc + kw.difficulty, 0) / primaryKeywords.length),
          avgCPC: parseFloat((primaryKeywords.reduce((acc, kw) => acc + kw.cpc, 0) / primaryKeywords.length).toFixed(2))
        },
        keywords: primaryKeywords
      });
      setLoading(false);
    }, 1500);
  };

  return (
    <div className="max-w-4xl mx-auto">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold mb-6">High-Value Digital Marketing Keywords</h2>
        
        <button
          onClick={analyzeKeywords}
          disabled={loading}
          className="w-full bg-blue-600 text-white py-3 px-6 rounded-md hover:bg-blue-700 transition-colors disabled:opacity-50 mb-8"
        >
          {loading ? 'Analyzing...' : 'Analyze Top Keywords'}
        </button>

        {results && (
          <div className="space-y-8">
            {/* Overview Stats */}
            <div className="grid grid-cols-3 gap-4">
              <div className="bg-gray-50 p-4 rounded-lg">
                <div className="text-sm text-gray-600 mb-1">Total Monthly Volume</div>
                <div className="text-2xl font-bold">{results.overview.totalVolume.toLocaleString()}</div>
              </div>
              <div className="bg-gray-50 p-4 rounded-lg">
                <div className="text-sm text-gray-600 mb-1">Avg. Difficulty</div>
                <div className="text-2xl font-bold">{results.overview.avgDifficulty}/100</div>
              </div>
              <div className="bg-gray-50 p-4 rounded-lg">
                <div className="text-sm text-gray-600 mb-1">Avg. CPC</div>
                <div className="text-2xl font-bold">${results.overview.avgCPC}</div>
              </div>
            </div>

            {/* Keyword List */}
            <div>
              <h3 className="font-semibold mb-4">Top 10 Keywords by Commercial Value</h3>
              <div className="space-y-4">
                {results.keywords.map((kw: any, index: number) => (
                  <div key={index} className="bg-white border rounded-lg p-4">
                    <div className="flex justify-between items-start">
                      <div>
                        <div className="font-medium mb-2">{kw.term}</div>
                        <div className="text-sm text-gray-600">
                          Volume: {kw.volume.toLocaleString()} | CPC: ${kw.cpc}
                        </div>
                      </div>
                      <div className="text-right">
                        <div className="text-sm text-gray-600 mb-1">Difficulty</div>
                        <div className="inline-flex items-center px-2.5 py-0.5 rounded text-sm font-medium bg-blue-100 text-blue-800">
                          {kw.difficulty}/100
                        </div>
                      </div>
                    </div>
                    <div className="mt-2">
                      <div className="w-full bg-gray-200 rounded-full h-2">
                        <div 
                          className="bg-blue-600 h-2 rounded-full" 
                          style={{ width: `${kw.difficulty}%` }}
                        ></div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Recommendations */}
            <div className="bg-blue-50 p-6 rounded-lg">
              <h3 className="font-semibold mb-4">Strategic Recommendations</h3>
              <ul className="space-y-2">
                <li className="flex items-center">
                  <Award className="w-5 h-5 text-blue-600 mr-2" />
                  Focus on long-tail variations of "digital marketing agency"
                </li>
                <li className="flex items-center">
                  <Globe className="w-5 h-5 text-blue-600 mr-2" />
                  Target location-specific variations for local SEO
                </li>
                <li className="flex items-center">
                  <TrendingUp className="w-5 h-5 text-blue-600 mr-2" />
                  Create content clusters around each main keyword
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}