import React from 'react';
import { BlogPostCard } from './BlogPostCard';
import { BlogPost } from '../../types';

interface BlogPostListProps {
  posts: BlogPost[];
  category?: string;
}

export function BlogPostList({ posts, category }: BlogPostListProps) {
  // Sort posts by date (newest first) and isMainTopic
  const sortedPosts = [...posts].sort((a, b) => {
    if (a.isMainTopic && !b.isMainTopic) return -1;
    if (!a.isMainTopic && b.isMainTopic) return 1;
    return new Date(b.date).getTime() - new Date(a.date).getTime();
  });

  const filteredPosts = category 
    ? sortedPosts.filter(post => post.category.toLowerCase() === category.toLowerCase())
    : sortedPosts;

  if (filteredPosts.length === 0) {
    return (
      <div className="text-center py-8">
        <p className="text-gray-600">No posts found.</p>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      {filteredPosts.map((post) => (
        <BlogPostCard key={post.id} post={post} />
      ))}
    </div>
  );
}