import React, { useState } from 'react';
import { SEOHead } from '../../components/SEOHead';
import { ArrowRight, CheckCircle, XCircle, Search, MapPin, BarChart, Activity } from 'lucide-react';
import { CTAButton } from '../../components/CTAButton';
import { KeywordTool } from '../../components/tools/KeywordTool';
import { GBPRankingChecker } from '../../components/tools/GBPRankingChecker';
import { CompetitorAnalysis } from '../../components/tools/CompetitorAnalysis';
import { OptInNotice } from '../../components/OptInNotice';
import { saveLead } from '../../utils/leadManagement';
import { ToolSelector } from '../../components/tools/ToolSelector';
import { SEOScoreForm } from '../../components/tools/SEOScoreForm';
import { SEOScoreResults } from '../../components/tools/SEOScoreResults';

type ToolType = 'seo-score' | 'gbp-ranking' | 'keyword-research' | 'competitor-analysis';

const seoSchema = {
  "@context": "https://schema.org",
  "@type": "WebApplication",
  "name": "SEO Tools Suite",
  "description": "Comprehensive SEO tools including Local SEO Score Calculator, Google Business Profile Ranking Checker, Keyword Research, and Competitor Analysis.",
  "applicationCategory": "SEO Tool",
  "operatingSystem": "Any",
  "offers": {
    "@type": "Offer",
    "price": "0",
    "priceCurrency": "USD"
  },
  "provider": {
    "@type": "Organization",
    "name": "Gold Standard Local SEO",
    "url": "https://goldstandardlocalseo.com"
  }
};

export function SEOScoreCalculator() {
  const [activeTool, setActiveTool] = useState<ToolType>('seo-score');
  const [showResults, setShowResults] = useState(false);
  const [scores, setScores] = useState<any[]>([]);
  const [totalScore, setTotalScore] = useState(0);

  const handleToolChange = (tool: ToolType) => {
    setActiveTool(tool);
    setShowResults(false);
  };

  const handleResults = (results: any) => {
    setScores(results.scores);
    setTotalScore(results.totalScore);
    setShowResults(true);
  };

  return (
    <>
      <SEOHead
        title="Free SEO Tools Suite | SEO Score, GBP Ranking & Competitor Analysis"
        description="Comprehensive SEO tools including Local SEO Score Calculator, Google Business Profile Ranking Checker, Keyword Research, and Competitor Analysis. Improve your local search visibility today."
        canonicalUrl="/tools/seo-score-calculator"
        schema={seoSchema}
      />

      <section className="py-16">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto">
            <ToolSelector activeTool={activeTool} onToolChange={handleToolChange} />

            <div className="bg-white p-8 rounded-lg shadow-lg">
              {activeTool === 'seo-score' && (
                !showResults ? (
                  <SEOScoreForm onResults={handleResults} />
                ) : (
                  <SEOScoreResults scores={scores} totalScore={totalScore} />
                )
              )}

              {activeTool === 'gbp-ranking' && (
                <GBPRankingChecker />
              )}

              {activeTool === 'keyword-research' && (
                <KeywordTool />
              )}

              {activeTool === 'competitor-analysis' && (
                <CompetitorAnalysis />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}