import React from 'react';
import { Info } from 'lucide-react';

interface OptInNoticeProps {
  onConsentChange: (consent: boolean) => void;
}

export function OptInNotice({ onConsentChange }: OptInNoticeProps) {
  return (
    <div className="mt-6 space-y-4">
      <div className="flex items-start space-x-2">
        <div className="flex items-center h-5">
          <input
            id="marketing-consent"
            name="marketing-consent"
            type="checkbox"
            onChange={(e) => onConsentChange(e.target.checked)}
            className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor="marketing-consent" className="font-medium text-gray-700">
            Marketing Communications & AI Assistant
          </label>
          <p className="text-gray-500">
            I agree to receive marketing communications and interact with the AI assistant for personalized support. 
            You can unsubscribe or disable the AI assistant at any time.
          </p>
        </div>
      </div>

      <div className="bg-gray-50 p-4 rounded-lg flex items-start space-x-2">
        <Info className="w-5 h-5 text-blue-500 flex-shrink-0 mt-0.5" />
        <div className="text-sm text-gray-600">
          <p className="font-medium mb-1">How we use your information:</p>
          <ul className="list-disc list-inside space-y-1">
            <li>Provide your requested analysis</li>
            <li>Power our AI assistant for personalized support</li>
            <li>Send relevant marketing communications (if opted in)</li>
            <li>Improve our tools and services</li>
          </ul>
          <p className="mt-2">
            Your data is securely stored and never shared with third parties. View our{' '}
            <a href="/privacy-policy" className="text-blue-600 hover:underline">Privacy Policy</a>{' '}
            for more details.
          </p>
        </div>
      </div>
    </div>
  );
}