import React from 'react';
import { Calendar, ArrowRight } from 'lucide-react';

// Simplified static news items
const newsItems = [
  {
    title: "The Impact of AI on Local SEO in 2024",
    date: "2024-03-15",
    description: "Discover how artificial intelligence is reshaping local search and what it means for your business.",
    link: "/blog/ai-impact-local-seo-2024"
  },
  {
    title: "Google Business Profile: Essential Updates for 2024",
    date: "2024-03-14",
    description: "Stay ahead of the competition with these crucial Google Business Profile optimization strategies.",
    link: "/blog/google-business-profile-updates-2024"
  },
  {
    title: "Local SEO Success Stories: Folsom Businesses",
    date: "2024-03-13",
    description: "Learn how local businesses in Folsom achieved remarkable growth through strategic SEO.",
    link: "/blog/local-seo-success-stories"
  }
];

export function RSSFeedList() {
  return (
    <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
      {newsItems.map((item, index) => (
        <article key={index} className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow">
          <div className="flex items-center text-sm text-gray-500 mb-2">
            <Calendar className="w-4 h-4 mr-2" />
            <time dateTime={item.date}>
              {new Date(item.date).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
              })}
            </time>
          </div>
          <h3 className="text-xl font-semibold mb-2">
            <a 
              href={item.link}
              className="hover:text-blue-600 transition-colors"
            >
              {item.title}
            </a>
          </h3>
          <p className="text-gray-600 mb-4">
            {item.description}
          </p>
          <a
            href={item.link}
            className="inline-flex items-center text-blue-600 hover:text-blue-700"
          >
            Read More <ArrowRight className="w-4 h-4 ml-2" />
          </a>
        </article>
      ))}
    </div>
  );
}