import React, { useState } from 'react';
import { Mic, MicOff } from 'lucide-react';

export function VoiceSearch() {
  const [isListening, setIsListening] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const startVoiceSearch = () => {
    if (!('webkitSpeechRecognition' in window)) {
      setError('Voice search is not supported in your browser');
      return;
    }

    const recognition = new (window as any).webkitSpeechRecognition();
    recognition.continuous = false;
    recognition.interimResults = false;
    recognition.lang = 'en-US';

    recognition.onstart = () => {
      setIsListening(true);
      setError(null);
    };

    recognition.onerror = (event: any) => {
      setError('Error occurred in recognition: ' + event.error);
      setIsListening(false);
    };

    recognition.onend = () => {
      setIsListening(false);
    };

    recognition.onresult = (event: any) => {
      const transcript = event.results[0][0].transcript;
      handleVoiceCommand(transcript);
    };

    recognition.start();
  };

  const handleVoiceCommand = (transcript: string) => {
    // Common voice command patterns
    const patterns = {
      search: /^(search|find|look for|show me)/i,
      contact: /^(contact|call|email)/i,
      analyze: /^(analyze|check|audit)/i,
      directions: /^(directions|how to get|where is)/i
    };

    let url = '/search';

    if (patterns.search.test(transcript)) {
      url = `/search?q=${encodeURIComponent(transcript.replace(patterns.search, '').trim())}`;
    } else if (patterns.contact.test(transcript)) {
      url = '/contact';
    } else if (patterns.analyze.test(transcript)) {
      url = '/tools/seo-score-calculator';
    } else if (patterns.directions.test(transcript)) {
      url = '/contact#location';
    }

    window.location.href = url;
  };

  return (
    <button
      onClick={startVoiceSearch}
      disabled={isListening}
      className="p-2 rounded-full hover:bg-gray-100 transition-colors relative"
      aria-label={isListening ? 'Listening...' : 'Start voice search'}
    >
      {isListening ? (
        <Mic className="w-5 h-5 text-blue-600 animate-pulse" />
      ) : (
        <Mic className="w-5 h-5" />
      )}
      
      {error && (
        <div className="absolute top-full mt-2 right-0 bg-red-50 border border-red-200 rounded-lg p-2 text-sm text-red-600 whitespace-nowrap">
          {error}
        </div>
      )}
    </button>
  );
}