import React from 'react';
import { Search, MapPin, BarChart, Activity, Link2, Star, MessageCircle, Globe } from 'lucide-react';
import { Link } from 'react-router-dom';

const tools = [
  {
    id: 'seo-score',
    name: 'SEO Score Calculator',
    description: 'Get a comprehensive analysis of your website\'s SEO performance',
    icon: BarChart,
    path: '/tools/seo-score-calculator',
    color: 'blue'
  },
  {
    id: 'gbp-ranking',
    name: 'GBP Ranking Checker',
    description: 'Check your Google Business Profile ranking and optimization',
    icon: MapPin,
    path: '/tools/gbp-ranking',
    color: 'red'
  },
  {
    id: 'keyword-research',
    name: 'Keyword Research',
    description: 'Find the best keywords for your local business',
    icon: Search,
    path: '/tools/keyword-research',
    color: 'green'
  },
  {
    id: 'competitor-analysis',
    name: 'Competitor Analysis',
    description: 'Analyze your competitors\' digital presence',
    icon: Activity,
    path: '/tools/competitor-analysis',
    color: 'purple'
  },
  {
    id: 'backlink-analyzer',
    name: 'Backlink Analyzer',
    description: 'Check your website\'s backlink profile',
    icon: Link2,
    path: '/tools/backlink-analyzer',
    color: 'orange'
  },
  {
    id: 'review-monitor',
    name: 'Review Monitor',
    description: 'Track and analyze your online reviews',
    icon: Star,
    path: '/tools/review-monitor',
    color: 'yellow'
  },
  {
    id: 'citation-checker',
    name: 'Citation Checker',
    description: 'Find and fix local business citations',
    icon: Globe,
    path: '/tools/citation-checker',
    color: 'indigo'
  },
  {
    id: 'local-presence',
    name: 'Local Presence Audit',
    description: 'Audit your local digital presence',
    icon: MessageCircle,
    path: '/tools/local-presence',
    color: 'teal'
  }
];

export function ToolsDashboard() {
  return (
    <div className="py-8 px-4 md:py-16 md:px-8">
      <div className="container mx-auto">
        <div className="text-center mb-12">
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Free SEO Tools</h1>
          <p className="text-lg md:text-xl text-gray-600 max-w-2xl mx-auto">
            Comprehensive tools to analyze and improve your local search presence
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
          {tools.map((tool) => (
            <Link
              key={tool.id}
              to={tool.path}
              className="block group"
            >
              <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-xl transition-shadow h-full">
                <div className={`w-12 h-12 rounded-lg bg-${tool.color}-100 flex items-center justify-center mb-4 group-hover:scale-110 transition-transform`}>
                  <tool.icon className={`w-6 h-6 text-${tool.color}-600`} />
                </div>
                <h3 className="text-lg font-semibold mb-2">{tool.name}</h3>
                <p className="text-gray-600 text-sm">{tool.description}</p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}