import React from 'react';
import { Link } from 'react-router-dom';
import { Calendar, User, ArrowRight } from 'lucide-react';
import { BlogPost } from '../../types';
import { formatDate } from '../../utils/dateUtils';
import { BlogImage } from './BlogImage';

interface BlogPostCardProps {
  post: BlogPost;
}

export function BlogPostCard({ post }: BlogPostCardProps) {
  return (
    <article className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow">
      <BlogImage
        src={post.image}
        alt={`Featured image for ${post.title}`}
        title={post.title}
        description={post.excerpt}
        width={640}
        height={360}
        className="w-full h-48 object-cover"
      />
      <div className="p-6">
        <div className="flex items-center text-sm text-gray-500 mb-4">
          <Calendar className="w-4 h-4 mr-2" />
          <time dateTime={post.date} className="mr-4">{formatDate(post.date)}</time>
          <User className="w-4 h-4 mr-2" />
          <span>{post.author}</span>
        </div>
        <h2 className="text-xl font-semibold mb-4">
          <Link to={`/blog/${post.slug}`} className="hover:text-blue-600 transition-colors">
            {post.title}
          </Link>
        </h2>
        <p className="text-gray-600 mb-4 line-clamp-3">{post.excerpt}</p>
        <Link
          to={`/blog/${post.slug}`}
          className="inline-flex items-center text-blue-600 hover:text-blue-700 transition-colors"
        >
          Read More <ArrowRight className="w-4 h-4 ml-2" />
        </Link>
      </div>
    </article>
  );
}