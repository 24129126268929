import React from 'react';
import { Helmet } from 'react-helmet-async';
import { socialMediaLinks, socialMediaHandles, socialMediaSchema } from '../config/socialMedia';

interface SEOHeadProps {
  // Previous props remain the same...
}

export function SEOHead({ 
  // Previous props destructuring remains the same...
}: SEOHeadProps) {
  // Previous code remains the same...

  return (
    <Helmet>
      {/* Previous meta tags remain the same... */}

      {/* RSS Feed */}
      <link 
        rel="alternate" 
        type="application/rss+xml" 
        title="Gold Standard Local SEO Blog RSS Feed"
        href="/rss.xml"
      />

      {/* Previous schema and other tags remain the same... */}
    </Helmet>
  );
}