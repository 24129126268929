import { mockApiCall } from './mockApi';

interface ApiResponse<T> {
  data?: T;
  error?: string;
}

const isDevelopment = process.env.NODE_ENV === 'development';
const API_BASE_URL = isDevelopment ? '' : 'https://api.goldstandardlocalseo.com';

export async function apiClient<T>(
  endpoint: string,
  options: RequestInit = {}
): Promise<ApiResponse<T>> {
  try {
    if (isDevelopment) {
      const mockData = await mockApiCall<T>(endpoint, options);
      return { data: mockData };
    }

    const response = await fetch(`${API_BASE_URL}${endpoint}`, {
      ...options,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        ...options.headers
      }
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.error || `HTTP error! status: ${response.status}`);
    }

    return { data };
  } catch (error) {
    console.error('API error:', error);
    return {
      error: error instanceof Error ? error.message : 'An unknown error occurred'
    };
  }
}