import React from 'react';
import { SEOHead } from '../components/SEOHead';
import { BlogCategories } from '../components/blog/BlogCategories';
import { BlogPostList } from '../components/blog/BlogPostList';
import { allBlogPosts } from '../data/blogPosts';

export function BlogPage() {
  const schema = {
    "@context": "https://schema.org",
    "@type": "Blog",
    "name": "Gold Standard Local SEO Blog",
    "description": "Expert digital marketing insights, tips, and strategies from Folsom's premier SEO agency.",
    "publisher": {
      "@type": "Organization",
      "name": "Gold Standard Local SEO",
      "logo": {
        "@type": "ImageObject",
        "url": "/logo.png"
      }
    }
  };

  return (
    <>
      <SEOHead
        title="Digital Marketing Blog | Expert SEO & Marketing Insights"
        description="Stay up-to-date with the latest digital marketing trends, tips, and strategies. Expert insights from Folsom's premier SEO and marketing agency."
        canonicalUrl="/blog"
        schema={schema}
      />

      {/* Hero Section */}
      <section className="bg-gradient-to-r from-secondary-800 to-secondary-900 text-white py-20">
        <div className="container mx-auto px-4">
          <h1 className="text-5xl font-bold mb-6">
            Digital Marketing Insights
          </h1>
          <p className="text-xl max-w-2xl">
            Expert tips, strategies, and insights to help your business succeed online.
          </p>
        </div>
      </section>

      {/* Blog Content */}
      <section className="py-16">
        <div className="container mx-auto px-4">
          <BlogCategories />
          <BlogPostList posts={allBlogPosts} />
        </div>
      </section>
    </>
  );
}