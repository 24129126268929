import React from 'react';
import { Link } from 'react-router-dom';
import { allBlogPosts } from '../../data/blogPosts';

interface TopicalNavProps {
  currentSlug: string;
  cluster: string;
  mainTopic?: string;
}

export function TopicalNav({ currentSlug, cluster, mainTopic }: TopicalNavProps) {
  // Get all posts in this cluster
  const clusterPosts = allBlogPosts.filter(post => post.topicalCluster === cluster);
  
  // Find main topic post if it exists
  const mainTopicPost = mainTopic ? 
    clusterPosts.find(post => post.slug === mainTopic) :
    clusterPosts.find(post => post.isMainTopic);
    
  // Get related subtopic posts
  const relatedPosts = clusterPosts.filter(post => 
    post.slug !== currentSlug && 
    post.slug !== mainTopicPost?.slug
  );

  if (relatedPosts.length === 0) return null;

  return (
    <nav className="bg-gray-50 p-6 rounded-lg mb-8">
      <h2 className="text-xl font-semibold mb-4">Related Articles</h2>
      <div className="space-y-4">
        {mainTopicPost && mainTopicPost.slug !== currentSlug && (
          <div>
            <h3 className="font-medium mb-2">Main Topic:</h3>
            <Link 
              to={`/blog/${mainTopicPost.slug}`}
              className="text-blue-600 hover:text-blue-700 transition-colors"
            >
              {mainTopicPost.title}
            </Link>
          </div>
        )}
        {relatedPosts.length > 0 && (
          <div>
            <h3 className="font-medium mb-2">Related Topics:</h3>
            <ul className="space-y-2">
              {relatedPosts.map(post => (
                <li key={post.slug}>
                  <Link 
                    to={`/blog/${post.slug}`}
                    className="text-blue-600 hover:text-blue-700 transition-colors"
                  >
                    {post.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
}