import React, { useState } from 'react';
import { Search, TrendingUp, BarChart, Globe, Award } from 'lucide-react';
import { analyzeCompetitor } from '../../utils/competitorAnalysis';

export function CompetitorAnalysis() {
  const [competitor, setCompetitor] = useState('');
  const [location, setLocation] = useState('');
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);

  const handleAnalysis = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const data = await analyzeCompetitor(competitor, location);
      setResults(data);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to analyze competitor');
      console.error('Analysis error:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto">
      <h2 className="text-2xl font-bold mb-6">Competitor Analysis Tool</h2>
      
      <form onSubmit={handleAnalysis} className="space-y-6 mb-8">
        <div>
          <label htmlFor="competitor" className="block text-sm font-medium text-gray-700 mb-1">
            Competitor Website
          </label>
          <input
            type="url"
            id="competitor"
            value={competitor}
            onChange={(e) => setCompetitor(e.target.value)}
            placeholder="https://example.com"
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>
        
        <div>
          <label htmlFor="location" className="block text-sm font-medium text-gray-700 mb-1">
            Location
          </label>
          <input
            type="text"
            id="location"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            placeholder="e.g., Sacramento, CA"
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>

        {error && (
          <div className="bg-red-50 border border-red-200 rounded-lg p-4">
            <p className="text-red-800 text-sm">{error}</p>
          </div>
        )}

        <button
          type="submit"
          disabled={loading}
          className="w-full bg-blue-600 text-white py-3 px-6 rounded-md hover:bg-blue-700 transition-colors disabled:opacity-50"
        >
          {loading ? 'Analyzing...' : 'Analyze Competitor'}
        </button>
      </form>

      {results && (
        <div className="space-y-8">
          {/* Overview */}
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            <div className="bg-gray-50 p-4 rounded-lg">
              <div className="flex items-center text-sm text-gray-600 mb-1">
                <Award className="w-4 h-4 mr-1" />
                Domain Authority
              </div>
              <div className="text-2xl font-bold">{results.overview.domainAuthority}</div>
            </div>
            <div className="bg-gray-50 p-4 rounded-lg">
              <div className="flex items-center text-sm text-gray-600 mb-1">
                <Globe className="w-4 h-4 mr-1" />
                Backlinks
              </div>
              <div className="text-2xl font-bold">{results.overview.totalBacklinks}</div>
            </div>
            <div className="bg-gray-50 p-4 rounded-lg">
              <div className="flex items-center text-sm text-gray-600 mb-1">
                <Search className="w-4 h-4 mr-1" />
                Keywords
              </div>
              <div className="text-2xl font-bold">{results.overview.organicKeywords}</div>
            </div>
            <div className="bg-gray-50 p-4 rounded-lg">
              <div className="flex items-center text-sm text-gray-600 mb-1">
                <TrendingUp className="w-4 h-4 mr-1" />
                Monthly Traffic
              </div>
              <div className="text-2xl font-bold">{results.overview.monthlyTraffic}</div>
            </div>
          </div>

          {/* Top Keywords */}
          <div>
            <h3 className="font-semibold mb-4">Top Performing Keywords</h3>
            <div className="space-y-4">
              {results.topKeywords.map((kw: any, index: number) => (
                <div key={index} className="bg-white border rounded-lg p-4">
                  <div className="flex justify-between items-center">
                    <div>
                      <div className="font-medium">{kw.term}</div>
                      <div className="text-sm text-gray-600">
                        Position: {kw.position} | Volume: {kw.volume}
                      </div>
                    </div>
                    <div className="text-sm font-semibold">
                      Difficulty: {kw.difficulty}%
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Content Gaps */}
          <div>
            <h3 className="font-semibold mb-4">Content Gap Opportunities</h3>
            <div className="space-y-4">
              {results.contentGaps.map((gap: any, index: number) => (
                <div key={index} className="bg-white border rounded-lg p-4">
                  <div className="flex justify-between items-start">
                    <div>
                      <div className="font-medium mb-2">{gap.topic}</div>
                      <div className="text-sm text-gray-600">
                        Suggested Keywords: {gap.suggestedKeywords.join(', ')}
                      </div>
                    </div>
                    <span className={`px-2 py-1 rounded text-sm font-medium ${
                      gap.opportunity === 'high' ? 'bg-green-100 text-green-800' :
                      gap.opportunity === 'medium' ? 'bg-yellow-100 text-yellow-800' :
                      'bg-gray-100 text-gray-800'
                    }`}>
                      {gap.opportunity} opportunity
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Backlinks */}
          <div>
            <h3 className="font-semibold mb-4">Top Backlinks</h3>
            <div className="space-y-4">
              {results.backlinks.map((link: any, index: number) => (
                <div key={index} className="bg-white border rounded-lg p-4">
                  <div className="flex justify-between items-center">
                    <div className="font-medium">{link.domain}</div>
                    <div className="flex items-center space-x-4">
                      <span className="text-sm text-gray-600">DA: {link.authority}</span>
                      <span className={`px-2 py-1 rounded text-sm ${
                        link.type === 'dofollow' ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-800'
                      }`}>
                        {link.type}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}