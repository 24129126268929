import { fetchWithTimeout } from '../fetchUtils';

export interface ContentGap {
  topic: string;
  opportunity: 'high' | 'medium' | 'low';
  suggestedKeywords: string[];
}

export async function analyzeContentGaps(domain: string, location: string): Promise<ContentGap[]> {
  try {
    // Real API call to content analysis API would go here
    const response = await fetchWithTimeout(
      `https://api.content-analysis.com/v1/gaps?domain=${domain}&location=${location}`,
      {
        headers: {
          'Authorization': `Bearer ${process.env.CONTENT_API_KEY}`,
          'Content-Type': 'application/json'
        },
        timeout: 10000
      }
    );

    if (!response.ok) {
      throw new Error('Failed to fetch content gap data');
    }

    // For development, generate realistic content gap data based on domain and location
    const hash = hashString(domain + location);
    return generateContentGaps(domain, location, hash);
  } catch (error) {
    console.error('Content gap analysis error:', error);
    throw new Error('Failed to analyze content gaps');
  }
}

function generateContentGaps(domain: string, location: string, hash: number): ContentGap[] {
  const topics = ['Local SEO', 'Social Media', 'Content Marketing', 'PPC', 'Web Design'];
  const opportunities = ['high', 'medium', 'low'] as const;
  
  return topics.map((topic, index) => {
    const opportunity = opportunities[(hash + index) % 3];
    const keywords = generateKeywordsForTopic(topic, location, hash + index);
    
    return {
      topic: `${topic} for ${location}`,
      opportunity,
      suggestedKeywords: keywords
    };
  });
}

function generateKeywordsForTopic(topic: string, location: string, hash: number): string[] {
  const modifiers = ['best', 'top', 'affordable', 'expert', 'professional'];
  return modifiers.map(modifier => 
    `${modifier} ${topic.toLowerCase()} ${location}`
  );
}

function hashString(str: string): number {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash;
  }
  return Math.abs(hash);
}