import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { registerSW } from 'virtual:pwa-register';
import App from './App.tsx';
import './index.css';
import { initAnalytics } from './utils/analytics';
import { reportWebVitals } from './utils/performance';

// Initialize error tracking
window.onerror = (message, source, lineno, colno, error) => {
  console.error('Global error:', { message, source, lineno, colno, error });
  // Send to error tracking service
  fetch('/api/error-logging', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      type: 'global',
      message,
      source,
      lineno,
      colno,
      error: error?.toString(),
      timestamp: new Date().toISOString(),
      url: window.location.href,
      userAgent: window.navigator.userAgent
    }),
    keepalive: true
  }).catch(console.error);
  return false;
};

// Initialize analytics
initAnalytics();

// Register service worker
if ('serviceWorker' in navigator) {
  registerSW();
}

// Report Web Vitals
reportWebVitals();

const root = createRoot(document.getElementById('root')!);

root.render(
  <StrictMode>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </StrictMode>
);