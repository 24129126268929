import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Phone, Mail, Clock, MapPin, Menu, X, Search, ChevronDown } from 'lucide-react';
import { SearchBar } from './SearchBar';

interface NavLinkProps {
  href: string;
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
}

function NavLink({ href, children, onClick, className = '' }: NavLinkProps) {
  const location = useLocation();
  const isActive = location.pathname === href;

  return (
    <Link
      to={href}
      className={`text-gray-300 hover:text-primary transition-colors block py-2 ${
        isActive ? 'text-primary font-semibold' : ''
      } ${className}`}
      onClick={onClick}
    >
      {children}
    </Link>
  );
}

export function Navigation() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isToolsOpen, setIsToolsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const closeMenu = () => {
    setIsMenuOpen(false);
    setIsToolsOpen(false);
  };

  const handleSearch = (query: string) => {
    if (query.trim()) {
      navigate(`/search?q=${encodeURIComponent(query)}`);
      closeMenu();
    }
  };

  return (
    <header className="bg-secondary-900 shadow-lg relative z-50">
      {/* Top Bar */}
      <div className="bg-gradient-to-r from-secondary-800 to-secondary-900 text-white py-2">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap justify-between items-center text-sm">
            <div className="flex items-center space-x-4">
              <a href="tel:801-893-8093" className="flex items-center hover:text-primary">
                <Phone className="h-4 w-4 mr-1" />
                <span className="hidden sm:inline">801-893-8093</span>
              </a>
              <a href="mailto:contact@goldstandardassets.com" className="flex items-center hover:text-primary">
                <Mail className="h-4 w-4 mr-1" />
                <span className="hidden sm:inline">contact@goldstandardassets.com</span>
              </a>
            </div>
            <div className="flex items-center space-x-4">
              <div className="flex items-center">
                <Clock className="h-4 w-4 mr-1" />
                <span className="hidden sm:inline">7AM - 7PM (Mon-Sat)</span>
              </div>
              <div className="flex items-center">
                <MapPin className="h-4 w-4 mr-1" />
                <span>Folsom, CA</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Navigation */}
      <nav className="container mx-auto px-4 py-4">
        <div className="flex justify-between items-center">
          <Link 
            to="/" 
            className="text-xl md:text-2xl font-bold text-primary" 
            aria-label="Gold Standard Local SEO Homepage"
            onClick={closeMenu}
          >
            <h1>Gold Standard Local SEO</h1>
          </Link>

          {/* Mobile Menu Button */}
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="md:hidden text-white p-2 hover:text-primary transition-colors"
            aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
          >
            {isMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
          </button>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-6">
            <SearchBar onSearch={handleSearch} />
            <NavLink href="/">Home</NavLink>
            <NavLink href="/services">Services</NavLink>
            
            {/* Tools Dropdown */}
            <div className="relative group">
              <button 
                className="flex items-center text-gray-300 hover:text-primary transition-colors"
                onClick={() => setIsToolsOpen(!isToolsOpen)}
              >
                Tools
                <ChevronDown className="w-4 h-4 ml-1" />
              </button>
              
              <div className={`absolute left-0 mt-2 w-64 bg-white rounded-lg shadow-lg py-2 ${
                isToolsOpen ? 'block' : 'hidden'
              } group-hover:block`}>
                <Link 
                  to="/tools" 
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  onClick={closeMenu}
                >
                  All Tools
                </Link>
                <Link 
                  to="/tools/seo-score-calculator" 
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  onClick={closeMenu}
                >
                  SEO Score Calculator
                </Link>
                <Link 
                  to="/tools/gbp-ranking" 
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  onClick={closeMenu}
                >
                  GBP Ranking Checker
                </Link>
                <Link 
                  to="/tools/keyword-research" 
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  onClick={closeMenu}
                >
                  Keyword Research
                </Link>
              </div>
            </div>

            <NavLink href="/about">About</NavLink>
            <NavLink href="/blog">Blog</NavLink>
            <NavLink href="/contact">Contact</NavLink>

            <Link 
              to="/contact" 
              className="bg-primary text-secondary-900 px-6 py-2 rounded-full hover:bg-primary-300 transition-colors font-semibold"
            >
              Book Consultation
            </Link>
          </div>
        </div>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="md:hidden fixed inset-0 top-[137px] bg-secondary-900 overflow-y-auto">
            <div className="p-4 space-y-4">
              <SearchBar onSearch={handleSearch} />
              
              <div className="space-y-2">
                <NavLink href="/" onClick={closeMenu}>Home</NavLink>
                <NavLink href="/services" onClick={closeMenu}>Services</NavLink>
                
                {/* Mobile Tools Menu */}
                <div>
                  <button 
                    className="flex items-center justify-between w-full text-gray-300 hover:text-primary transition-colors py-2"
                    onClick={() => setIsToolsOpen(!isToolsOpen)}
                  >
                    <span>Tools</span>
                    <ChevronDown className={`w-4 h-4 transform transition-transform ${isToolsOpen ? 'rotate-180' : ''}`} />
                  </button>
                  
                  {isToolsOpen && (
                    <div className="pl-4 space-y-2 border-l-2 border-gray-700 ml-2">
                      <NavLink href="/tools" onClick={closeMenu}>All Tools</NavLink>
                      <NavLink href="/tools/seo-score-calculator" onClick={closeMenu}>SEO Score Calculator</NavLink>
                      <NavLink href="/tools/gbp-ranking" onClick={closeMenu}>GBP Ranking Checker</NavLink>
                      <NavLink href="/tools/keyword-research" onClick={closeMenu}>Keyword Research</NavLink>
                    </div>
                  )}
                </div>

                <NavLink href="/about" onClick={closeMenu}>About</NavLink>
                <NavLink href="/blog" onClick={closeMenu}>Blog</NavLink>
                <NavLink href="/contact" onClick={closeMenu}>Contact</NavLink>
              </div>

              <Link 
                to="/contact" 
                className="block bg-primary text-secondary-900 px-6 py-3 rounded-full hover:bg-primary-300 transition-colors font-semibold text-center mt-6"
                onClick={closeMenu}
              >
                Book Consultation
              </Link>
            </div>
          </div>
        )}
      </nav>
    </header>
  );
}