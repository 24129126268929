import { apiClient } from './apiClient';

interface LeadData {
  name: string;
  email: string;
  website?: string;
  source: string;
  toolUsed?: string;
  analysisResults?: any;
  consent: boolean;
}

interface ApiResponse {
  success: boolean;
  message: string;
}

export async function saveLead(data: LeadData) {
  try {
    const response = await apiClient<ApiResponse>('/api/leads', {
      method: 'POST',
      body: JSON.stringify(data)
    });

    if (response.error) {
      throw new Error(response.error);
    }

    // If user consented to marketing, add to email list
    if (data.consent) {
      await addToEmailList(data);
    }

    return response.data;
  } catch (error) {
    console.error('Error saving lead:', error);
    throw new Error('Failed to save lead information. Please try again.');
  }
}

async function addToEmailList(data: LeadData) {
  try {
    const response = await apiClient<ApiResponse>('/api/email', {
      method: 'POST',
      body: JSON.stringify({
        email: data.email,
        name: data.name,
        source: data.source,
        tags: [data.toolUsed || 'General Inquiry']
      })
    });

    if (response.error) {
      throw new Error(response.error);
    }
  } catch (error) {
    // Log error but don't throw - this is a non-critical operation
    console.error('Error adding to email list:', error);
  }
}