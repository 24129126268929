import React, { useState } from 'react';
import { Star, TrendingUp, MessageCircle } from 'lucide-react';

export function ReviewMonitor() {
  const [businessName, setBusinessName] = useState('');
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<any>(null);

  const monitorReviews = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    // Simulate API call
    setTimeout(() => {
      setResults({
        totalReviews: 125,
        averageRating: 4.8,
        sentiment: {
          positive: 85,
          neutral: 10,
          negative: 5
        },
        recentReviews: [
          {
            platform: 'Google',
            rating: 5,
            comment: 'Great service!',
            date: '2024-04-15'
          }
        ],
        trends: {
          monthly: {
            reviews: 12,
            rating: 4.9
          }
        }
      });
      setLoading(false);
    }, 1500);
  };

  return (
    <div className="max-w-4xl mx-auto">
      <h2 className="text-2xl font-bold mb-6">Review Monitoring Tool</h2>
      
      <form onSubmit={monitorReviews} className="space-y-6 mb-8">
        <div>
          <label htmlFor="businessName" className="block text-sm font-medium text-gray-700 mb-1">
            Business Name
          </label>
          <input
            type="text"
            id="businessName"
            value={businessName}
            onChange={(e) => setBusinessName(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>

        <button
          type="submit"
          disabled={loading}
          className="w-full bg-blue-600 text-white py-3 px-6 rounded-md hover:bg-blue-700 transition-colors disabled:opacity-50"
        >
          {loading ? 'Analyzing...' : 'Monitor Reviews'}
        </button>
      </form>

      {results && (
        <div className="space-y-8">
          {/* Overview */}
          <div className="grid grid-cols-3 gap-4">
            <div className="bg-gray-50 p-4 rounded-lg">
              <div className="flex items-center text-sm text-gray-600 mb-1">
                <MessageCircle className="w-4 h-4 mr-1" />
                Total Reviews
              </div>
              <div className="text-2xl font-bold">{results.totalReviews}</div>
            </div>
            <div className="bg-gray-50 p-4 rounded-lg">
              <div className="flex items-center text-sm text-gray-600 mb-1">
                <Star className="w-4 h-4 mr-1" />
                Average Rating
              </div>
              <div className="text-2xl font-bold">{results.averageRating}</div>
            </div>
            <div className="bg-gray-50 p-4 rounded-lg">
              <div className="flex items-center text-sm text-gray-600 mb-1">
                <TrendingUp className="w-4 h-4 mr-1" />
                Monthly Reviews
              </div>
              <div className="text-2xl font-bold">{results.trends.monthly.reviews}</div>
            </div>
          </div>

          {/* Sentiment Analysis */}
          <div>
            <h3 className="font-semibold mb-4">Review Sentiment</h3>
            <div className="bg-white border rounded-lg p-4">
              <div className="space-y-4">
                {Object.entries(results.sentiment).map(([type, percentage]: [string, any]) => (
                  <div key={type}>
                    <div className="flex justify-between text-sm mb-1">
                      <span className="capitalize">{type}</span>
                      <span>{percentage}%</span>
                    </div>
                    <div className="h-2 bg-gray-200 rounded-full">
                      <div 
                        className={`h-full rounded-full ${
                          type === 'positive' ? 'bg-green-500' :
                          type === 'neutral' ? 'bg-yellow-500' : 'bg-red-500'
                        }`}
                        style={{ width: `${percentage}%` }}
                      ></div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Recent Reviews */}
          <div>
            <h3 className="font-semibold mb-4">Recent Reviews</h3>
            <div className="space-y-4">
              {results.recentReviews.map((review: any, index: number) => (
                <div key={index} className="bg-white border rounded-lg p-4">
                  <div className="flex justify-between items-start">
                    <div>
                      <div className="flex items-center mb-2">
                        <span className="font-medium mr-2">{review.platform}</span>
                        <div className="flex">
                          {[...Array(5)].map((_, i) => (
                            <Star
                              key={i}
                              className={`w-4 h-4 ${
                                i < review.rating ? 'text-yellow-400 fill-current' : 'text-gray-300'
                              }`}
                            />
                          ))}
                        </div>
                      </div>
                      <p className="text-gray-600">{review.comment}</p>
                    </div>
                    <span className="text-sm text-gray-500">{review.date}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}