import React from 'react';
import { Facebook, Twitter, Linkedin, Instagram } from 'lucide-react';
import { socialMediaLinks } from '../config/socialMedia';

export function Footer() {
  return (
    <footer className="bg-secondary-900 text-white">
      <div className="container mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <h3 className="text-xl font-bold mb-4 text-primary">Gold Standard Local SEO</h3>
            <p className="text-gray-400">
              Your trusted partner for digital marketing excellence in Folsom and beyond.
            </p>
            <div className="flex space-x-4 mt-4">
              <a 
                href={socialMediaLinks.facebook}
                target="_blank" 
                rel="noopener noreferrer"
                className="hover:text-primary transition-colors"
                aria-label="Follow us on Facebook"
              >
                <Facebook className="h-5 w-5" />
              </a>
              <a 
                href={socialMediaLinks.twitter}
                target="_blank" 
                rel="noopener noreferrer"
                className="hover:text-primary transition-colors"
                aria-label="Follow us on X (Twitter)"
              >
                <Twitter className="h-5 w-5" />
              </a>
              <a 
                href={socialMediaLinks.linkedin}
                target="_blank" 
                rel="noopener noreferrer"
                className="hover:text-primary transition-colors"
                aria-label="Connect with us on LinkedIn"
              >
                <Linkedin className="h-5 w-5" />
              </a>
              <a 
                href={socialMediaLinks.instagram}
                target="_blank" 
                rel="noopener noreferrer"
                className="hover:text-primary transition-colors"
                aria-label="Follow us on Instagram"
              >
                <Instagram className="h-5 w-5" />
              </a>
            </div>
          </div>
          
          {/* Rest of the footer content remains the same */}
        </div>
      </div>
    </footer>
  );
}