import React from 'react';
import { CheckCircle, XCircle } from 'lucide-react';
import { CTAButton } from '../CTAButton';

interface SEOScoreResultsProps {
  scores: Array<{
    category: string;
    score: number;
  }>;
  totalScore: number;
}

export function SEOScoreResults({ scores, totalScore }: SEOScoreResultsProps) {
  return (
    <div className="space-y-8">
      <div className="text-center">
        <div className="text-4xl font-bold text-blue-600 mb-2">
          {totalScore}/100
        </div>
        <p className="text-gray-600">Overall SEO Score</p>
      </div>

      <div className="space-y-4">
        {scores.map((item, index) => (
          <div key={index} className="bg-gray-50 p-4 rounded-lg">
            <div className="flex justify-between items-center mb-2">
              <span className="font-semibold">{item.category}</span>
              <span className="flex items-center">
                {item.score >= 70 ? (
                  <CheckCircle className="w-5 h-5 text-green-500 mr-2" />
                ) : (
                  <XCircle className="w-5 h-5 text-red-500 mr-2" />
                )}
                {item.score}/100
              </span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2">
              <div
                className={`h-2 rounded-full ${
                  item.score >= 70 ? 'bg-green-500' : 'bg-red-500'
                }`}
                style={{ width: `${item.score}%` }}
              ></div>
            </div>
          </div>
        ))}
      </div>

      <div className="text-center">
        <CTAButton
          text="Get Your Free SEO Consultation"
          href="/contact"
          className="text-lg"
        />
      </div>
    </div>
  );
}