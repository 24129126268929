// Initialize analytics
export function initAnalytics() {
  if (typeof window !== 'undefined') {
    // Google Analytics
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-XXXXXXXXXX', {
      send_page_view: false // We'll send page views manually
    });

    // Track initial page view
    trackPageView(window.location.pathname);

    // Track route changes
    window.addEventListener('popstate', () => {
      trackPageView(window.location.pathname);
    });
  }
}

// Track page views
export function trackPageView(path: string) {
  if (typeof window !== 'undefined') {
    // Google Analytics
    window.gtag?.('event', 'page_view', {
      page_path: path,
      page_title: document.title,
      page_location: window.location.href
    });

    // Track performance metrics
    const navigationEntry = performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming;
    if (navigationEntry) {
      trackEvent('Performance', 'Page Load Time', path, navigationEntry.duration);
    }
  }
}

// Track events
export function trackEvent(
  category: string,
  action: string,
  label?: string,
  value?: number,
  metadata: Record<string, any> = {}
) {
  if (typeof window !== 'undefined') {
    // Google Analytics
    window.gtag?.('event', action, {
      event_category: category,
      event_label: label,
      value: value,
      ...metadata
    });

    // Custom event tracking
    const eventData = {
      category,
      action,
      label,
      value,
      metadata,
      timestamp: new Date().toISOString(),
      path: window.location.pathname
    };

    // Send to custom analytics endpoint
    fetch('/api/events', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(eventData),
      keepalive: true
    }).catch(console.error);
  }
}