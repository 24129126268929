import React from 'react';
import { BarChart, MapPin, Search, Activity } from 'lucide-react';

interface ToolSelectorProps {
  activeTool: string;
  onToolChange: (tool: string) => void;
}

export function ToolSelector({ activeTool, onToolChange }: ToolSelectorProps) {
  return (
    <div className="flex justify-center mb-8 overflow-x-auto">
      <nav className="flex flex-nowrap gap-2 p-2 bg-gray-100 rounded-lg">
        <button
          onClick={() => onToolChange('seo-score')}
          className={`px-4 py-2 rounded-lg flex items-center whitespace-nowrap ${
            activeTool === 'seo-score' 
              ? 'bg-blue-600 text-white' 
              : 'hover:bg-gray-200'
          }`}
        >
          <BarChart className="w-5 h-5 mr-2" />
          SEO Score
        </button>
        <button
          onClick={() => onToolChange('gbp-ranking')}
          className={`px-4 py-2 rounded-lg flex items-center whitespace-nowrap ${
            activeTool === 'gbp-ranking' 
              ? 'bg-blue-600 text-white' 
              : 'hover:bg-gray-200'
          }`}
        >
          <MapPin className="w-5 h-5 mr-2" />
          GBP Ranking
        </button>
        <button
          onClick={() => onToolChange('keyword-research')}
          className={`px-4 py-2 rounded-lg flex items-center whitespace-nowrap ${
            activeTool === 'keyword-research' 
              ? 'bg-blue-600 text-white' 
              : 'hover:bg-gray-200'
          }`}
        >
          <Search className="w-5 h-5 mr-2" />
          Keyword Research
        </button>
        <button
          onClick={() => onToolChange('competitor-analysis')}
          className={`px-4 py-2 rounded-lg flex items-center whitespace-nowrap ${
            activeTool === 'competitor-analysis' 
              ? 'bg-blue-600 text-white' 
              : 'hover:bg-gray-200'
          }`}
        >
          <Activity className="w-5 h-5 mr-2" />
          Competitor Analysis
        </button>
      </nav>
    </div>
  );
}