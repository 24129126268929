import React, { useState } from 'react';
import { MapPin, Check, AlertTriangle } from 'lucide-react';

export function LocalCitationChecker() {
  const [businessName, setBusinessName] = useState('');
  const [address, setAddress] = useState('');
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<any>(null);

  const checkCitations = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    // Simulate API call
    setTimeout(() => {
      setResults({
        totalCitations: 45,
        accuracy: 85,
        directories: [
          {
            name: 'Yelp',
            status: 'accurate',
            url: 'https://yelp.com/biz/example'
          },
          {
            name: 'Yellow Pages',
            status: 'inaccurate',
            issues: ['Wrong phone number']
          }
        ],
        opportunities: [
          'BBB Directory Listing',
          'Chamber of Commerce Directory'
        ]
      });
      setLoading(false);
    }, 1500);
  };

  return (
    <div className="max-w-4xl mx-auto">
      <h2 className="text-2xl font-bold mb-6">Local Citation Checker</h2>
      
      <form onSubmit={checkCitations} className="space-y-6 mb-8">
        <div>
          <label htmlFor="businessName" className="block text-sm font-medium text-gray-700 mb-1">
            Business Name
          </label>
          <input
            type="text"
            id="businessName"
            value={businessName}
            onChange={(e) => setBusinessName(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>

        <div>
          <label htmlFor="address" className="block text-sm font-medium text-gray-700 mb-1">
            Business Address
          </label>
          <input
            type="text"
            id="address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>

        <button
          type="submit"
          disabled={loading}
          className="w-full bg-blue-600 text-white py-3 px-6 rounded-md hover:bg-blue-700 transition-colors disabled:opacity-50"
        >
          {loading ? 'Checking...' : 'Check Citations'}
        </button>
      </form>

      {results && (
        <div className="space-y-8">
          {/* Overview */}
          <div className="grid grid-cols-2 gap-4">
            <div className="bg-gray-50 p-4 rounded-lg">
              <div className="text-3xl font-bold text-blue-600 mb-1">
                {results.totalCitations}
              </div>
              <div className="text-sm text-gray-600">Total Citations</div>
            </div>
            <div className="bg-gray-50 p-4 rounded-lg">
              <div className="text-3xl font-bold text-blue-600 mb-1">
                {results.accuracy}%
              </div>
              <div className="text-sm text-gray-600">Citation Accuracy</div>
            </div>
          </div>

          {/* Directory Listings */}
          <div>
            <h3 className="font-semibold mb-4">Directory Listings</h3>
            <div className="space-y-4">
              {results.directories.map((directory: any, index: number) => (
                <div key={index} className="bg-white border rounded-lg p-4">
                  <div className="flex justify-between items-center">
                    <div>
                      <div className="font-medium">{directory.name}</div>
                      {directory.issues && (
                        <div className="text-sm text-red-600">
                          {directory.issues.join(', ')}
                        </div>
                      )}
                    </div>
                    <div>
                      {directory.status === 'accurate' ? (
                        <Check className="w-5 h-5 text-green-500" />
                      ) : (
                        <AlertTriangle className="w-5 h-5 text-yellow-500" />
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Opportunities */}
          <div>
            <h3 className="font-semibold mb-4">Citation Opportunities</h3>
            <div className="bg-blue-50 p-4 rounded-lg">
              <ul className="space-y-2">
                {results.opportunities.map((opportunity: string, index: number) => (
                  <li key={index} className="flex items-center">
                    <MapPin className="w-5 h-5 text-blue-500 mr-2" />
                    {opportunity}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}