import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Layout } from './components/Layout';
import { LoadingSpinner } from './components/LoadingSpinner';
import { ErrorBoundary } from './components/ErrorBoundary';
import { HomePage } from './pages/HomePage';
import { SearchPage } from './pages/SearchPage';
import { BlogPage } from './pages/BlogPage';
import { BlogPostPage } from './pages/blog/BlogPostPage';
import { ToolsDashboard } from './components/tools/ToolsDashboard';
import { SEOScoreCalculator } from './pages/tools/SEOScoreCalculator';
import { GBPRankingChecker } from './components/tools/GBPRankingChecker';
import { KeywordTool } from './components/tools/KeywordTool';
import { CompetitorAnalysis } from './components/tools/CompetitorAnalysis';
import { BacklinkAnalyzer } from './components/tools/BacklinkAnalyzer';
import { ReviewMonitor } from './components/tools/ReviewMonitor';
import { LocalCitationChecker } from './components/tools/LocalCitationChecker';
import { ContactPage } from './pages/ContactPage';
import { AboutPage } from './pages/AboutPage';
import { ServicesPage } from './pages/ServicesPage';

export default function App() {
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <Layout>
          <React.Suspense fallback={<LoadingSpinner />}>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/search" element={<SearchPage />} />
              <Route path="/blog" element={<BlogPage />} />
              <Route path="/blog/:slug" element={<BlogPostPage />} />
              <Route path="/tools" element={<ToolsDashboard />} />
              <Route path="/tools/seo-score-calculator" element={<SEOScoreCalculator />} />
              <Route path="/tools/gbp-ranking" element={<GBPRankingChecker />} />
              <Route path="/tools/keyword-research" element={<KeywordTool />} />
              <Route path="/tools/competitor-analysis" element={<CompetitorAnalysis />} />
              <Route path="/tools/backlink-analyzer" element={<BacklinkAnalyzer />} />
              <Route path="/tools/review-monitor" element={<ReviewMonitor />} />
              <Route path="/tools/citation-checker" element={<LocalCitationChecker />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/services" element={<ServicesPage />} />
            </Routes>
          </React.Suspense>
        </Layout>
      </BrowserRouter>
    </ErrorBoundary>
  );
}