import React from 'react';
import { SEOHead } from '../components/SEOHead';
import { RSSFeedList } from '../components/RSSFeed/RSSFeedList';
import { LocalPresence } from '../components/LocalPresence';
import { ArrowRight, Award, BarChart, Users } from 'lucide-react';
import { CTAButton } from '../components/CTAButton';

export function HomePage() {
  const schema = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Gold Standard Local SEO - Folsom's Premier Digital Marketing Agency",
    "description": "Transform your Folsom business with our data-driven digital marketing strategies. Get more customers, increase revenue, and outperform your competition.",
    "provider": {
      "@type": "LocalBusiness",
      "name": "Gold Standard Local SEO",
      "image": "/logo.png",
      "priceRange": "$$$",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "705 Gold Lake Drive, Suite 250",
        "addressLocality": "Folsom",
        "addressRegion": "CA",
        "postalCode": "95630",
        "addressCountry": "US"
      },
      "geo": {
        "@type": "GeoCoordinates",
        "latitude": 38.672767,
        "longitude": -121.165277
      },
      "url": "https://goldstandardlocalseo.com",
      "telephone": "801-893-8093",
      "openingHoursSpecification": [
        {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
          "opens": "07:00",
          "closes": "19:00"
        }
      ],
      "sameAs": [
        "https://www.facebook.com/goldstandardassets",
        "https://x.com/GoldStandard24k",
        "https://www.linkedin.com/in/goldstandard/",
        "https://www.instagram.com/gold_standard24k/"
      ],
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "4.9",
        "reviewCount": "127"
      }
    }
  };

  return (
    <>
      <SEOHead
        title="Best Local SEO & Digital Marketing Agency in Folsom, CA"
        description="Transform your Folsom business with our proven digital marketing strategies. Expert Local SEO, PPC & Social Media services. Get more customers & increase revenue. Free consultation!"
        canonicalUrl="/"
        schema={schema}
        keywords={[
          'best seo company folsom',
          'top digital marketing agency folsom',
          'local seo expert folsom ca',
          'google business profile optimization folsom',
          'ppc management folsom california',
          'social media marketing sacramento area'
        ]}
      />

      {/* Hero Section */}
      <section className="bg-gradient-to-r from-secondary-800 to-secondary-900 text-white py-20">
        <div className="container mx-auto px-4">
          <h1 className="text-5xl font-bold mb-6">
            Dominate Local Search Results in Folsom
          </h1>
          <p className="text-xl max-w-2xl mb-8">
            Transform your online presence with data-driven digital marketing strategies 
            that deliver measurable results.
          </p>
          <CTAButton
            text="Get Your Free SEO Analysis"
            href="/contact"
            className="text-lg"
          />
        </div>
      </section>

      {/* Local Presence */}
      <LocalPresence />

      {/* Latest News Section */}
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Latest Digital Marketing News</h2>
          <RSSFeedList />
        </div>
      </section>

      {/* Services Section */}
      <section className="py-16">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">
            Our Digital Marketing Services
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {services.map((service, index) => (
              <div key={index} className="bg-white p-8 rounded-lg shadow-lg hover:shadow-xl transition-all">
                <service.icon className="w-12 h-12 text-primary mb-4" />
                <h3 className="text-xl font-semibold mb-4">{service.title}</h3>
                <p className="text-gray-600 mb-4">{service.description}</p>
                <a
                  href={service.link}
                  className="inline-flex items-center text-primary hover:text-primary-600"
                >
                  Learn More <ArrowRight className="w-4 h-4 ml-2" />
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Stats Section */}
      <section className="bg-secondary-900 text-white py-16">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            {stats.map((stat, index) => (
              <div key={index} className="text-center">
                <div className="text-4xl font-bold text-primary mb-2">
                  {stat.value}
                </div>
                <p className="text-gray-300">{stat.label}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-16">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-6">
            Ready to Grow Your Business?
          </h2>
          <p className="text-xl mb-8 max-w-2xl mx-auto">
            Schedule a free consultation to discover how we can help you attract more customers 
            and grow your business.
          </p>
          <CTAButton
            text="Schedule Free Consultation"
            href="/contact"
            className="text-lg"
          />
        </div>
      </section>
    </>
  );
}

const services = [
  {
    icon: Award,
    title: "Local SEO",
    description: "Dominate local search results and attract more customers in Folsom and surrounding areas.",
    link: "/services/local-seo"
  },
  {
    icon: BarChart,
    title: "PPC Management",
    description: "Drive targeted traffic and leads with expertly managed Google Ads campaigns.",
    link: "/services/ppc"
  },
  {
    icon: Users,
    title: "Social Media Marketing",
    description: "Build brand awareness and engage with your target audience on social platforms.",
    link: "/services/social-media"
  }
];

const stats = [
  { value: "250+", label: "Clients Served" },
  { value: "485%", label: "Average ROI" },
  { value: "15+", label: "Years Experience" },
  { value: "98%", label: "Client Retention" }
];